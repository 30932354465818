import LoadingScreen from 'components/LoadingScreen'
import React, { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Toaster } from 'react-hot-toast'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import * as serviceWorker from './serviceWorker'
import './styles/tailwind.output.css'

const LandingPage = lazy(() => import('routes/LandingPage'))
const AppRoute = lazy(() => import('routes/AppRoute'))

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<LoadingScreen />}>
      <Router>
        <Switch>
          <Route path='/landingPage' component={LandingPage} />
          <Route path='/' component={AppRoute} />
        </Switch>
        <Toaster />
      </Router>
    </Suspense>
  </React.StrictMode>,

  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
