import Spinner from 'base-components/Spinner'
import React, { FC } from 'react'

interface Props {}

const LoadingScreen: FC<Props> = ({ children }) => {
  return (
    <div className='min-h-screen flex items-center justify-center'>
      <Spinner />
    </div>
  )
}

export default LoadingScreen
